import React, { useState } from 'react';
import styled from '@emotion/styled';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { useFormik } from 'formik';

import Button from '@zd/components/button';
import Input from '@zd/components/input';
import theme from '@zd/theme';

const WrapperDiv = styled.div`
  width: 100%;
  border-radius: 20px;
  background: linear-gradient(138.12deg, #9257de 0%, #592cba 100%);
  box-shadow: 0 10px 40px 0 rgba(89, 44, 186, 0.33);
  display: block;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 2rem;

  ${theme.medias.gteLarge} {
    display: flex;
  }
`;

const LeftDiv = styled.div`
  & > h4 {
    color: ${theme.colors.white};
    font-size: 1.5rem;
    margin-bottom: 1rem;

    ${theme.medias.gteLarge} {
      margin-bottom: 1rem;
      font-size: 2rem;
    }
  }

  & > div {
    background: ${theme.colors.secondaryBlue};
    width: 100px;
    height: 5px;
    margin-bottom: 1rem;
  }

  & > p {
    color: ${theme.colors.white};
    margin-bottom: 1rem;
    font-size: 0.95rem;

    ${theme.medias.gteLarge} {
      display: flex;
      margin-bottom: 0;
      font-size: 1rem;
      padding-right: 1.5rem;
    }
  }
`;

const Form = styled.form`
  margin-bottom: 0;
`;

const StyledInput = styled(Input)`
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  border-radius: 25px;
  background-color: #fdf8f3;
  border: none;
  padding: 1rem;
  margin-bottom: 0.25rem;

  ${theme.medias.gteLarge} {
    width: 20rem;
    margin-bottom: 0.75rem;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 0.5rem;
  margin-left: auto;
  width: 100%;

  ${theme.medias.gteLarge} {
    width: auto;
  }
`;

const SubscribeForm = () => {
  const [confirmationText, setConfirmationText] = useState();

  const formik = useFormik({
    initialValues: {
      email: '',
      acceptTerms: false,
    },
    onSubmit: async values => {
      const result = await addToMailchimp(values.email);
      const success = result.result === 'success';

      setConfirmationText(success ? 'Obrigado por subscrever!' : 'Whoops, ocorreu um erro!');
    },
  });

  return (
    <WrapperDiv>
      <LeftDiv>
        <h4>{confirmationText || 'Subscreva a nossa newsletter'}</h4>
        <div />
        <p>
          Interessado em ser mais feliz? Então não perca os conteúdos que enviamos gratuitamente,
          para que os possa aplicar, neste seu caminho, de se tornar a melhor versão de si mesmo.
        </p>
      </LeftDiv>
      {!confirmationText && (
        <Form onSubmit={formik.handleSubmit}>
          <StyledInput
            type="email"
            name="email"
            placeholder="Escreva o seu email"
            value={formik.values.email}
            onChange={formik.handleChange}
            required
          />
          <StyledButton type="submit" color="secondary" title="Submeter">
            Submeter
          </StyledButton>
        </Form>
      )}
    </WrapperDiv>
  );
};

export default SubscribeForm;
